import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Login from '../auth/Login';
import Register from '../auth/Register';
import Dashboard from '../dashboard/Dashboard';
import MainMenu from '../dashboard/MainMenu';
import ProfileBox from '../dashboard/ProfileBox';
import Timetable from '../dashboard/Timetable';
import Landing from './Landing';
import Alert from './alert';

import PrivateRoutes from '../routing/PrivateRoutes';

import {
  getCurrentUserProfile,
  getCurrentUserTasks,
} from '../../actions/userProfile';

import { getSettings } from '../../actions/params';

//Permissions
import PermissionProvider from '../../PermissionProvider/PermissionProvider';
import Restricted from '../../PermissionProvider/Restricted';

//Forms

import Button from 'devextreme-react/button';
import SectionBuilder from '../../OnlineTrainings/pages/Editor/Builder';

import FormCouponSell from '../forms/Administration/Diak/FormCouponSell';
import FormCouponUse from '../forms/Administration/Diak/FormCouponUse';
import FormDiakDijak from '../forms/Administration/Kimutatasok/FormDiakDijak';
import FormEredmeny from '../forms/Administration/Kimutatasok/FormEredmeny';
import FormTeacherBalance from '../forms/Administration/Kimutatasok/FormTeacherBalance';
import FormTeacherPayments from '../forms/Administration/Oktato/FormTeacherPayments';
import FormTeacherSkills from '../forms/Administration/Oktato/FormTeacherSkills';
import FormCosts from '../forms/Administration/Penzugyek/FormCosts';

import FormLatogatottsag from '../forms/Administration/Kimutatasok/FormLatogatottsag';
import FormStudentDashboard from '../forms/Administration/Kimutatasok/StudentView/FormStudentDashboard';
import FormIncome from '../forms/Administration/Penzugyek/FormIncome';
import FormRegistrations from '../forms/Administration/Registration/FormRegistrations';
import FormMailCampaign from '../forms/Communication/FormMailCampaign';
import FormHomework from '../forms/Homework/FormHomework';
import FormHianyzasokKezelese from '../forms/Invoicing/FormHianyzasokKezelese';
import FormManualInvoice from '../forms/Invoicing/FormManualInvoice';
import FormSystemSettings from '../forms/Params/FormSystemSettings';
import CalendarForm from '../forms/Schedule/CalendarForm';

const FormTorzsadat = loadable(() =>
  import('../forms/Administration/Torzsadat/FormTorzsadat')
);
const BlogEditor = loadable(() =>
  import('../forms/Palanta/BlogEditor/BlogEditor')
);

const FormPrepareKedvezmeny = loadable(() =>
  import('../forms/Invoicing/FormPrepareKedvezmeny')
);
const FormSMSValidate = loadable(() =>
  import('../forms/Communication/FormSMSValidate')
);
const FormSMSCreate = loadable(() =>
  import('../forms/Communication/FormSMSCreate')
);

const FormSystemParams = loadable(() =>
  import('../forms/Params/FormSystemParams')
);
const FormUserParams = loadable(() => import('../forms/Params/FormUserParams'));
const FormSystemTasks = loadable(() =>
  import('../forms/TaskManagement/FormSystemTasks')
);

const HintTemplateEditor = loadable(() =>
  import('../../OnlineTrainings/pages/Editor/HintTemplateEditor')
);
const ExerciseCatalog = loadable(() =>
  import('../../OnlineTrainings/pages/ExerciseCatalog/ExerciseCatalog')
);

//const Timetable = loadable(() => import('../dashboard/Timetable'));

const FormSubject = loadable(() => import('../forms/MasterData/FormSubject'));

const FormStudentPayments = loadable(() =>
  import('../forms/Administration/Diak/FormStudentPayments')
);
const FormStudentRates = loadable(() =>
  import('../forms/Administration/Diak/FormStudentRates')
);
const FormOpenHours = loadable(() =>
  import('../forms/Administration/FormOpenHours')
);

const FormStudentBalance = loadable(() =>
  import('../forms/Administration/Kimutatasok/FormStudentBalance')
);
const FormTeacherSalary = loadable(() =>
  import('../forms/Administration/Oktato/FormTeacherSalary')
);
const FormDiakDiscounts = loadable(() =>
  import('../forms/Administration/Penzugyek/FormDiakDiscounts')
);

const FormInvoiceDiscounts = loadable(() =>
  import('../forms/Administration/Penzugyek/FormInvoiceDiscounts')
);
const FormPriceList = loadable(() =>
  import('../forms/Administration/Penzugyek/FormPriceList')
);
const FormTanora = loadable(() =>
  import('../forms/Administration/Tanora/FormTanora')
);
const FormTanoraDiakok = loadable(() =>
  import('../forms/Administration/Tanora/FormTanoraDiakok')
);
const FormTanoraWizzard = loadable(() =>
  import('../forms/Administration/Tanora/FormTanoraWizzard')
);

const FormAutmaticInvoices = loadable(() =>
  import('../forms/Invoicing/FormAutmaticInvoices')
);

const FormApiRights = loadable(() =>
  import('../forms/PermissionAdmin/FormApiRights')
);
const FormAPIs = loadable(() => import('../forms/PermissionAdmin/FormAPIs'));
const FormManageUsers = loadable(() =>
  import('../forms/PermissionAdmin/FormManageUsers')
);
const FormPermissionsToRoles = loadable(() =>
  import('../forms/PermissionAdmin/FormPermissionsToRoles')
);
const FormUserPermissions = loadable(() =>
  import('../forms/PermissionAdmin/FormUserPermissions')
);
const FormUserRights = loadable(() =>
  import('../forms/PermissionAdmin/FormUserRights')
);
const FormUserRoles = loadable(() =>
  import('../forms/PermissionAdmin/FormUserRoles')
);
const FormChangePassword = loadable(() =>
  import('../forms/UserProfile/FormChangePassword')
);
const FormCreateProfile = loadable(() =>
  import('../forms/UserProfile/FormCreateProfile')
);
const CapacityPlan = loadable(() => import('../dashboard/CapacityPlan'));

// Function that simulates fetching a permission from remote server
const fetchPermission = (user) => async (permission) => {
  // Simulate a delay from a request

  await new Promise((resolve) => setTimeout(resolve, 1000));
  if (user && user.permissions) {
    return user.permissions.includes(permission);
  } else {
    return false;
  }
};

const Main = ({
  getCurrentUserProfile,
  getCurrentUserTasks,
  getSettings,
  isAuthenticated,
  auth,
  userProfile: { profile, loading, tasks },
  timeTable: { timeTable },
}) => {
  const [smallMenu, toggleSmallMenu] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      getCurrentUserProfile();
      getCurrentUserTasks();
      getSettings();
    }
  }, [isAuthenticated]);

  const notAllowed = (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <h4>Nem elérhető </h4>
          Nincs jogosultságod megtekinteni az adott területet
        </div>
      </div>
    </div>
  );
  if (!isAuthenticated) {
    return (
      <section className='container'>
        <Fragment>
          <Routes>
            <Route exact path='/' element={<Landing />} />
            <Route exact path='/register' element={<Register />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/builderPreview' element={<SectionBuilder />} />

            <Route exact path='*' element={<Landing />} />
          </Routes>
        </Fragment>
      </section>
    );
  } else {
    return (
      <PermissionProvider fetchPermission={fetchPermission(auth.user)}>
        <section className='container main-block'>
          <div
            className='w3-container w3-content'
            style={{ maxWidth: '1800px', marginTop: '30px' }}
          >
            <Alert />
            <div className='w3-row'>
              {timeTable.status === 'closed' && (
                <div className={smallMenu ? 'w3-col m1' : 'w3-col m3'}>
                  {/* profile box */}
                  <Button
                    icon='chevrondoubleleft'
                    onClick={(e) => {
                      toggleSmallMenu(!smallMenu);
                    }}
                  />
                  {!smallMenu && (
                    <React.Fragment>
                      <ProfileBox
                        profile={profile}
                        tasks={tasks}
                        user={auth.user}
                      />
                      <br />
                      <Restricted to='main.menu' fallback={notAllowed}>
                        <MainMenu />
                      </Restricted>
                    </React.Fragment>
                  )}
                </div>
              )}
              <div
                className={
                  timeTable.status === 'closed'
                    ? smallMenu
                      ? 'w3-col m11'
                      : 'w3-col m9'
                    : 'w3-col m12'
                }
              >
                <Routes>
                  <Route exact path='/register' element={<Register />} />
                  <Route exact path='/login' element={<Login />} />
                  <Route
                    path='/Tanora'
                    element={
                      <PrivateRoutes>
                        <FormTanora />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/tanoraStudents'
                    element={
                      <PrivateRoutes>
                        <FormTanoraDiakok />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/studentDashboard'
                    element={
                      <PrivateRoutes>
                        <FormStudentDashboard />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path='/subjects'
                    element={
                      <PrivateRoutes>
                        <FormSubject />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path='/price-list'
                    element={
                      <PrivateRoutes>
                        <FormPriceList />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path='/diak-discounts'
                    element={
                      <PrivateRoutes>
                        <FormDiakDiscounts />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path='/CouponUseage'
                    element={
                      <PrivateRoutes>
                        <FormCouponUse />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path='/prepare-kedvezmeny'
                    element={
                      <PrivateRoutes>
                        <FormPrepareKedvezmeny />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path='/invoice-discounts'
                    element={
                      <PrivateRoutes>
                        <FormInvoiceDiscounts />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path='/users'
                    element={
                      <PrivateRoutes>
                        <FormManageUsers />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path='/teacherSalary'
                    element={
                      <PrivateRoutes>
                        <FormTeacherSalary />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path='/oktato-skills'
                    element={
                      <PrivateRoutes>
                        <FormTeacherSkills />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path='/studentRates'
                    element={
                      <PrivateRoutes>
                        <FormStudentRates />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path='/registrations'
                    element={
                      <PrivateRoutes>
                        <FormRegistrations />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path='/TanoraWizzard'
                    element={
                      <PrivateRoutes>
                        <FormTanoraWizzard />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/'
                    element={
                      <PrivateRoutes>
                        <Dashboard />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/scriptantia-timetable'
                    element={
                      <PrivateRoutes>
                        <Timetable />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/calendar'
                    element={
                      <PrivateRoutes>
                        <CalendarForm />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/system-tasks'
                    element={
                      <PrivateRoutes>
                        <FormSystemTasks />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/scriptantia-capacityPlan'
                    element={
                      <PrivateRoutes>
                        <CapacityPlan />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/create-profile'
                    element={
                      <PrivateRoutes>
                        <FormCreateProfile />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/change-password'
                    element={
                      <PrivateRoutes>
                        <FormChangePassword />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/manage-users'
                    element={
                      <PrivateRoutes>
                        <FormManageUsers />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/user-roles'
                    element={
                      <PrivateRoutes>
                        <FormUserRoles />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/user-permissions'
                    element={
                      <PrivateRoutes>
                        <FormUserPermissions />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/api-rights'
                    element={
                      <PrivateRoutes>
                        <FormApiRights />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/apis'
                    element={
                      <PrivateRoutes>
                        <FormAPIs />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/user-rights'
                    element={
                      <PrivateRoutes>
                        <FormUserRights />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/permission-to-role'
                    element={
                      <PrivateRoutes>
                        <FormPermissionsToRoles />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/invoice-generate'
                    element={
                      <PrivateRoutes>
                        <FormAutmaticInvoices />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/invoice-manual'
                    element={
                      <PrivateRoutes>
                        <FormManualInvoice />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/StudentPayements'
                    element={
                      <PrivateRoutes>
                        <FormStudentPayments />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/Hianyzasok'
                    element={
                      <PrivateRoutes>
                        <FormHianyzasokKezelese />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/TeacherPayments'
                    element={
                      <PrivateRoutes>
                        <FormTeacherPayments />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/Results'
                    element={
                      <PrivateRoutes>
                        <FormEredmeny />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/DiakDijak'
                    element={
                      <PrivateRoutes>
                        <FormDiakDijak />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/Latogatottsag'
                    element={
                      <PrivateRoutes>
                        <FormLatogatottsag />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/Costs'
                    element={
                      <PrivateRoutes>
                        <FormCosts />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/Income'
                    element={
                      <PrivateRoutes>
                        <FormIncome />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/MasterData'
                    element={
                      <PrivateRoutes>
                        <FormTorzsadat />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/StudentBalance'
                    element={
                      <PrivateRoutes>
                        <FormStudentBalance />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/TeacherBalance'
                    element={
                      <PrivateRoutes>
                        <FormTeacherBalance />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/MailCampaign'
                    element={
                      <PrivateRoutes>
                        <FormMailCampaign />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/OpenHours'
                    element={
                      <PrivateRoutes>
                        <FormOpenHours />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/SystemParams'
                    element={
                      <PrivateRoutes>
                        <FormSystemParams />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/SystemSettings'
                    element={
                      <PrivateRoutes>
                        <FormSystemSettings />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/UserParams'
                    element={
                      <PrivateRoutes>
                        <FormUserParams />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/sms-list'
                    element={
                      <PrivateRoutes>
                        <FormSMSValidate />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/sms-create'
                    element={
                      <PrivateRoutes>
                        <FormSMSCreate />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/CouponSell'
                    element={
                      <PrivateRoutes>
                        <FormCouponSell />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/ExerciseCatalogCreate'
                    element={
                      <PrivateRoutes>
                        <ExerciseCatalog
                          statusFrom={10}
                          statusTo={20}
                          editor={true}
                        />
                      </PrivateRoutes>
                    }
                  />{' '}
                  <Route
                    exact
                    path='/ExerciseCatalogApprove'
                    element={
                      <PrivateRoutes>
                        <ExerciseCatalog
                          statusFrom={20}
                          statusTo={49}
                          editor={false}
                        />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/ExerciseCatalogRelease'
                    element={
                      <PrivateRoutes>
                        <ExerciseCatalog
                          statusFrom={50}
                          statusTo={100}
                          editor={false}
                        />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/palanta-blog'
                    element={
                      <PrivateRoutes>
                        <BlogEditor />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/createHomework'
                    element={
                      <PrivateRoutes>
                        <FormHomework />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    exact
                    path='/HintEditor'
                    element={
                      <PrivateRoutes>
                        <HintTemplateEditor />
                      </PrivateRoutes>
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </section>
      </PermissionProvider>
    );
  }
};

Main.propTypes = {
  getCurrentUserProfile: PropTypes.func.isRequired,
  getCurrentUserTasks: PropTypes.func.isRequired,
  getSettings: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  profile: PropTypes.object,
  timeTable: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  userProfile: state.userProfile,
  timeTable: state.timeTable,
  params: state.params,
});

export default connect(mapStateToProps, {
  getCurrentUserProfile,
  getCurrentUserTasks,
  getSettings,
})(Main);

/*
 
                 
                   <PrivateRoutes path='/topics' Component={FormTopic} />

                 


                  */
