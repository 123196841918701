import 'devextreme-react/text-area';
import React, { useEffect, useState } from 'react';

import { locale } from 'devextreme/localization';
import { IntlProvider, FormattedNumber } from 'react-intl';
import './FormStudentDashboard.scss';
import BalanceBoard from './BalanceBoard';
import Lookup from 'devextreme-react/cjs/lookup';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { backendHOST } from '../../../../../client-config/client-config';
import { getUserBalance } from '../../../../../api/balance.js';

export default function FormStudentDashboard() {
  locale(navigator.language);

  const [BalanceValue, setBalance] = useState(0);
  const [selectedDiak, setDiak] = useState(null);

  useEffect(() => {
    async function apiCall() {
      const apiResponse2 = await getUserBalance(selectedDiak);
      if (apiResponse2) {
        setBalance(apiResponse2.data[0].Balance);
      }
    }
    apiCall();
  }, [selectedDiak]);

  function onDiakChanged(e) {
    if (e.value) {
      setDiak(e.value);
    }
  }

  const url = backendHOST + '/api/scriptantiaAdmin';
  const diakok = createStore({
    key: 'id_diak',
    loadMode: 'raw',
    loadUrl: `${url}/Student`,
    loadParams: { sort: { selector: 'text', desc: false } },
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
      if (method === 'load') {
        ajaxOptions.dataType = 'json';
        ajaxOptions.data = {
          uType: 'D',
        };
      }
    },
  });

  return (
    <IntlProvider locale='hu' defaultLocale='hu'>
      <React.Fragment>
        <h2 className={'content-block'}>Egyenleginfó</h2>
        <div className='option'>
          <Lookup
            dataSource={diakok}
            value={selectedDiak}
            onValueChanged={onDiakChanged}
            showClearButton={true}
            clearButtonText='Törlés'
            label='Diák kiválasztása'
            valueExpr='id'
            displayExpr='text'
            searchEnabled={true}
          />
        </div>
        <div className='balanceHeader'>
          <p>
            Aktuális egyenleg:{' '}
            <FormattedNumber
              value={BalanceValue}
              style='currency'
              currency='HUF'
              maximumSignificantDigits={0}
            />{' '}
            {BalanceValue >= 0 ? 'tartozás' : 'túlfizetés'}
          </p>
        </div>
        <div className={'content-block dx-card responsive-paddings'}>
          <BalanceBoard onUserBalanceChanged={setBalance} diak={selectedDiak} />
        </div>
      </React.Fragment>
    </IntlProvider>
  );
}
