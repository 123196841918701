import {
  Column,
  DataGrid,
  Export,
  GroupItem,
  Grouping,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import React, { useEffect, useState } from 'react';
import { getUserBalanceDetails } from '../../../../../api/balance.js';
import './BalanceBoard.scss';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';

export default function BalanceBoard({ onUserBalanceChanged, diak }) {
  const [balanceData, setData] = useState({});

  //  const [balance, setBalance] = useState(0);
  var balance = 0;
  useEffect(() => {
    async function apiCall() {
      const apiResponse = await getUserBalanceDetails(diak);
      if (apiResponse) {
        setData(apiResponse.data);
      }
    }
    apiCall();
  }, [diak]);

  function rowPrepared(e) {
    if (e.rowType === 'data') {
      if (e.data.rowColor === 'blBlue') {
        e.rowElement.style.backgroundColor = 'rgb(90, 102, 237)';
      }
      if (e.data.rowColor === 'blGreen') {
        e.rowElement.style.backgroundColor = 'rgb(60, 180, 60)';
      }
      if (e.data.rowColor === 'blRed') {
        e.rowElement.style.backgroundColor = 'rgb(180, 120, 60)';
      }
      if (e.data.rowColor === 'blRed2') {
        e.rowElement.style.backgroundColor = 'rgb(200, 20, 60)';
      }
    }
  }
  const onExportData = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Egyenleg-${diak}.xlsx`
        );
      });
    });
    e.cancel = true;
  };

  return (
    <React.Fragment>
      <div className='balanceBoard'>
        <DataGrid
          id='balanceGrid'
          dataSource={balanceData}
          keyExpr='tanev'
          key='tanev'
          showBorders={false}
          hoverStateEnabled={true}
          columnAutoWidth={true}
          rowAlternationEnabled={false}
          allowColumnReordering={false}
          paging={false}
          onRowPrepared={rowPrepared}
          onExporting={onExportData}
        >
          <Export enabled={true} fileName='Egyenleg' />
          <Grouping autoExpandAll={false} />
          <Column
            className='balanceData'
            dataField='tanev'
            caption='Tanév'
            groupIndex={1}
            allowSorting={false}
          ></Column>
          <Column
            dataField='tipusDescr'
            caption='Mozgásnem'
            width={100}
            className='balanceData'
            allowSorting={false}
          ></Column>
          <Column
            dataField='datum'
            caption='Dátum'
            displayFormat='yyyy.MM.dd'
            editorType='dxDateBox'
            dataType='date'
            width={120}
            allowSorting={false}
          ></Column>

          <Column
            dataField='time_disp'
            caption=''
            width={150}
            allowSorting={false}
          ></Column>

          <Column
            dataField='posDescr'
            caption='Tanóra'
            allowSorting={false}
          ></Column>

          <Column
            dataField='oraStatusDescr'
            caption='Állapot'
            allowSorting={false}
          ></Column>

          <Column
            dataField='osszeg'
            caption='Összeg'
            allowSorting={false}
          ></Column>
          <Column
            dataField='balance'
            caption='Egyenleg változás'
            allowSorting={false}
          ></Column>
          <Summary>
            <GroupItem
              column='osszeg'
              summaryType='sum'
              alignByColumn
              displayFormat='{0} Ft'
            />
            <TotalItem
              column='osszeg'
              summaryType='sum'
              displayFormat='Egyenleg: {0} Ft'
            />
          </Summary>
        </DataGrid>
      </div>
    </React.Fragment>
  );
}
