import { Fragment } from 'react';

import {
  Column,
  DataGrid,
  Editing,
  FilterPanel,
  HeaderFilter,
  Lookup,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { locale } from 'devextreme/localization';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';
import { backendHOST } from '../../../client-config/client-config';

import { logout } from '../../../actions/auth';
import { SystemSettingsAreaCodes } from '../../../utils/enums/adminEnums';
import { withRouter } from '../../hooks/withRouter';

const url = backendHOST + '/api/params';
const urlEnd = '/SystemSettings';

const FormSystemSettings = ({ history, logout, auth }) => {
  locale(navigator.language);
  const dataSource = createStore({
    key: ['SettingName', 'SettingArea', 'settingKey'],

    loadUrl: `${url}${urlEnd}`,

    insertUrl: `${url}${urlEnd}`,
    updateUrl: `${url}${urlEnd}`,
    deleteUrl: `${url}${urlEnd}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
    },
    // onAjaxError: onDataErrorOccurred,
  });

  return (
    <Fragment>
      <div className='dx-row-padding'>
        <div className='dx-col m12'>
          <div className='dx-card dx-round dx-white'>
            <div className='dx-container dx-padding'>
              <h6 className='dx-opacity'>Rendszer beállítások</h6>
            </div>

            <div className='dx-viewport'>
              <DataGrid
                id='dataGrid'
                dataSource={dataSource}
                keyExpr={['SettingName', 'SettingArea', 'settingKey']}
                key={['SettingName', 'SettingArea', 'settingKey']}
                showBorders={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
              >
                <Scrolling mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={10} />
                <FilterPanel visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Editing
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                  repaintChangesOnly
                  mode='row'
                ></Editing>

                <Column
                  dataField='SettingName'
                  caption='Beállítás kódja'
                  allowEditing={true}
                  visible={true}
                ></Column>
                <Column
                  dataField='SettingArea'
                  caption='Terület'
                  allowEditing={true}
                  visible={true}
                >
                  <Lookup
                    dataSource={SystemSettingsAreaCodes}
                    valueExpr='key'
                    displayExpr='name'
                  ></Lookup>
                </Column>
                <Column
                  dataField='settingKey'
                  caption='Beállítás kulcsa'
                  allowEditing={true}
                  visible={true}
                ></Column>
                <Column
                  dataField='SettingValue'
                  caption='Beállítás érték'
                  allowEditing={true}
                  visible={true}
                ></Column>
              </DataGrid>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FormSystemSettings.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(
  withRouter(FormSystemSettings)
);
