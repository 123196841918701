import { Fragment } from 'react';

import {
  Column,
  DataGrid,
  Editing,
  FilterPanel,
  GroupPanel,
  Grouping,
  GroupItem,
  Summary,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { locale } from 'devextreme/localization';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import 'devextreme-react/text-area';
import 'devextreme/data/odata/store';
import { backendHOST } from '../../../client-config/client-config';

import { logout } from '../../../actions/auth';
import { withRouter } from '../../hooks/withRouter';

const url = backendHOST + '/api/schedule';
const urlEnd = '/MissedSchedules';

const HianyzasokKezelese = ({ history, logout, auth }) => {
  locale(navigator.language);
  const dataSource = createStore({
    key: 'jelenlet_id',

    loadUrl: `${url}${urlEnd}`,
    updateUrl: `${url}${urlEnd}`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-auth-token': localStorage.token,
        'Access-Control-Allow-Origin': '*',
      };
    },
    // onAjaxError: onDataErrorOccurred,
  });

  const dateEditorOptions = {
    type: 'date',
    displayFormat: 'yyyy.MM.dd',
    useMaskBehavior: true,
    calendarOptions: {
      firstDayOfWeek: 1,
    },
  };

  return (
    <Fragment>
      <div className='dx-row-padding'>
        <div className='dx-col m12'>
          <div className='dx-card dx-round dx-white'>
            <div className='dx-container dx-padding'>
              <h6 className='dx-opacity'>Lemondott órák kezelése</h6>
            </div>

            <div className='dx-viewport'>
              <DataGrid
                id='dataGrid'
                dataSource={dataSource}
                keyExpr='jelenlet_id'
                key='jelenlet_id'
                showBorders={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
              >
                <Scrolling mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={10} />
                <FilterPanel visible={true} />
                <HeaderFilter visible={true} />
                <Grouping autoExpandAll={false} />
                <GroupPanel visible={true} />
                <SearchPanel visible={true} />
                <Editing
                  allowUpdating={true}
                  allowAdding={false}
                  allowDeleting={false}
                  repaintChangesOnly
                  mode='row'
                ></Editing>
                <Summary>
                  <GroupItem
                    column='diak.text'
                    displayFormat='{0} óra'
                    alignByColumn
                    summaryType='count'
                  />
                </Summary>
                <Column
                  dataField='jelenlet_id'
                  caption='ID'
                  allowEditing={false}
                  visible={true}
                ></Column>
                <Column
                  dataField='missed.tanev'
                  caption='Tanév'
                  allowEditing={false}
                  groupIndex={0}
                ></Column>
                <Column
                  dataField='diak.text'
                  caption='Diák'
                  allowEditing={false}
                  groupIndex={1}
                ></Column>
                <Column
                  dataField='datum'
                  caption='Dátum'
                  allowEditing={false}
                  visible={true}
                  editorType='dxDateBox'
                  editorOptions={dateEditorOptions}
                  defaultSortOrder='desc'
                  displayFormat='yyyy.MM.dd'
                  useMaskBehavior={true}
                  dataType='date'
                ></Column>{' '}
                <Column
                  dataField='time_from'
                  caption='Időpont'
                  allowEditing={false}
                  visible={true}
                ></Column>
                <Column
                  dataField='tanora.tanora_code'
                  caption='Óra'
                  allowEditing={false}
                  visible={true}
                ></Column>
                <Column
                  dataField='status_text'
                  caption='Állapot'
                  allowEditing={false}
                  visible={true}
                  groupIndex={2}
                ></Column>
                <Column
                  dataField='oradij'
                  caption='Óradíj'
                  allowEditing={false}
                  visible={true}
                ></Column>
                <Column
                  dataField='oktato_dij'
                  caption='Oktató bér'
                  allowEditing={false}
                  visible={true}
                ></Column>
                <Column
                  dataField='dij_ovr_diak'
                  caption='kézi óradíj'
                  allowEditing={true}
                  visible={true}
                ></Column>
                <Column
                  dataField='dij_ovr_oktato'
                  caption='oktató bére kézi'
                  allowEditing={true}
                  visible={true}
                ></Column>
              </DataGrid>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

HianyzasokKezelese.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(
  withRouter(HianyzasokKezelese)
);
